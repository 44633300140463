.bg_alert {
	background-color: rgb(241, 241, 241);
	border-radius: 10px;
	padding: 20px;
}

.bg_alert_dark {
	background-color: #1c1f25;
	border-radius: 10px;
	color: #fff;
	padding: 20px;
}

.sticky {
	position: -webkit-sticky;
	position: sticky;
	width: 280px;
	height: 490px;
	overflow-y: auto;
}

.container_message {
	position: relative;
	top: 20px;
}

.preview_template {
	background-color: #fff;
	border-radius: 7.5px;
	box-shadow: 0 1px 0.5px rgba(0, 0, 0, .25);
	min-height: 20px;
	position: relative;
	word-wrap: break-word;
	padding: 16px;

	&.inline {
		box-shadow: none;
		padding: 0;
		width: 300px;
	}

	&.preview_template_btn {
		text-align: center;
		color: #00a5f4;
		width: 100%;
		margin-top: 8px;
		border-top-left-radius: 7.5px;
		padding: 8px 16px;

		&.inline {
			border: 1px solid #00a5f4;
			margin-right: 6px;
			padding: 4px 8px;
		}
	}

	.preview_template_header {
		color: rgba(0, 0, 0, .76);
		font-size: 15px;
		font-weight: bold;
		line-height: 19px;
		margin-bottom: 8px;
		position: relative;

		.preview_template_image {
			width: 100%;
			height: 150px;
			background-position: center;
			background-size: cover;

			background-color: rgba(113, 134, 157, .15);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 60px;
			color: #71869d;
			border-radius: 4px;

		}

		.preview_template_video {
			background-color: rgba(113, 134, 157, .15);
			width: 100%;
			height: 150px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 60px;
			color: #71869d;
			border-radius: 4px;

			video {
				width: 100%;
				height: 150px
			}

		}

		span {
			position: initial;
			margin-top: 4px;
			width: 100%;
		}
	}

	.preview_template_footer {
		color: rgba(0, 0, 0, .45);
		font-size: 12px;
		line-height: 17px;
		margin-top: 8px;
	}
}

.preview_template_dark {
	background-color: rgba(24, 33, 39);
	border-radius: 7.5px;
	border-top-left-radius: 0;
	box-shadow: 0 1px 0.5px rgba(0, 0, 0, .25);
	min-height: 20px;
	position: relative;
	word-wrap: break-word;
	padding: 16px;

	&.inline {
		box-shadow: none;
		padding: 0;
		width: 300px;
	}

	&.preview_template_dark_btn {
		text-align: center;
		color: #00a5f4;
		width: 100%;
		margin-top: 8px;
		border-top-left-radius: 7.5px;
		padding: 8px 16px;

		&.inline {
			border: 1px solid #00a5f4;
			margin-right: 6px;
			padding: 4px 8px;
		}
	}

	.preview_template_dark_header {
		color: rgba(255, 255, 255, 0.76);
		font-size: 15px;
		font-weight: bold;
		line-height: 19px;
		margin-bottom: 8px;
		position: relative;

		.preview_template_dark_image {
			width: 100%;
			height: 150px;
			background-position: center;
			background-size: cover;

			background-color: rgba(113, 134, 157, .15);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 60px;
			color: #71869d;
			border-radius: 4px;

		}

		.preview_template_dark_video {
			background-color: rgba(113, 134, 157, .15);
			width: 100%;
			height: 150px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 60px;
			color: #71869d;
			border-radius: 4px;

			video {
				width: 100%;
				height: 150px
			}

		}

		span {
			position: initial;
			margin-top: 4px;
			width: 100%;
		}
	}

	.preview_template_dark_footer {
		color: rgba(85, 95, 102);
		font-size: 12px;
		line-height: 17px;
		margin-top: 8px;
	}
}