.footer {
    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;

        &:hover button {
            transform: scale(1.02);
            transition: transform 0.4s ease;
            background-color: rgb(71 + (255 - 71) * 0.8, 136 + (255 - 136) * 0.8, 51 + (255 - 51) * 0.8);;
        }
    }
}

.widthDorpdown {
    width: 20rem;
}
