.pulse {
  width: 22.4px;
  height: 22.4px;
  border-radius: 50%;
  background: #579743;
  box-shadow: 0 0 0 0 rgba(87, 151, 67, 0.5);
  animation: pulse-kefe91md 1.2000000000000002s infinite linear;
  position: absolute;
  left: 50%;
  top: 50%;
}

.pulse:before,
.pulse:after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  box-shadow: 0 0 0 0 rgba(87, 151, 67, 0.5);
  animation: inherit;
  animation-delay: -0.4s;
}

.pulse:after {
  animation-delay: -0.8s;
}

@keyframes pulse-kefe91md {
  100% {
    box-shadow: 0 0 0 44.8px #0000;
  }
}
.textloader {
  position: absolute;
  top: 380px;
  left: 47%;
}
