.loader {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #579743;
  box-shadow: 0 0 0 0 rgba(87, 151, 67, 0.5);
  animation: l2 1.5s infinite linear;
  position: relative;
  left: 50%;
  top: 50%;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  box-shadow: 0 0 0 0 rgba(87, 151, 67, 0.5);
  animation: inherit;
  animation-delay: -0.5s;
}
.loader:after {
  animation-delay: -1s;
}
@keyframes l2 {
  100% {
    box-shadow: 0 0 0 44.8px #0000;
  }
}
.textloader {
  white-space: nowrap;
  position: absolute;
  top: 80px;
  left: -25px;
}
